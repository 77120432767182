import React, { memo, useState, useContext } from "react";
import styles from "./Panel.module.css";
import useAddClickHandler from "./hooks/useAddClick";
import { X } from "lucide-react";

import { PanelContext } from "./PanelContext";

import CustomSlider from "./Slider";

interface PanelProps {
  onClose: () => void;
}

const Panel: React.FC<PanelProps> = ({ onClose }) => {
  const {
    constraint,
    setConstraint,
    desirability,
    setDesirability,
    gptFour,
    setGptFour,
    objective,
    setObjective,
    opportunity,
    setOpportunity,
    sdg,
    setSdg,
    temperature,
    setTemperature,
    unknown,
    setUnknown,
  } = useContext(PanelContext);

  const onChangeObjective = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setObjective(e.target.value);
  };

  const onChangeConstraint = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setConstraint(e.target.value);
  };

  const onTemperatureChange = (e: any, value: number | number[]) => {
    if (typeof value === "number") {
      setTemperature(value);
    }
  };

  const onUnknownChange = (e: any, value: number | number[]) => {
    if (typeof value === "number") {
      setUnknown(value);
    }
  };

  const onOpportunityChange = (e: any, value: number | number[]) => {
    if (typeof value === "number") {
      setOpportunity(value);
    }
  };

  const onDesirabilityChange = (e: any, value: number | number[]) => {
    if (typeof value === "number") {
      setDesirability(value);
    }
  };
  const onSdgChange = (e: any, value: number | number[]) => {
    if (typeof value === "number") {
      setSdg(value);
    }
  };

  const onTemperatureInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemperature(Number(e.target.value));
  };

  const onUnknownInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnknown(Number(e.target.value));
  };

  const onOpportunityInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpportunity(Number(e.target.value));
  };

  const onDesirabilityInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDesirability(Number(e.target.value));
  };

  const onSdgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSdg(Number(e.target.value));
  };

  const onGptCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setGptFour(true);
    } else {
      setGptFour(false);
    }
  };

  const closePanel = () => {
    onClose();
  };

  return (
    <div className={styles.panel}>
      <div className={styles.contextGroup}>
        <div className={styles.titleContainer}>
          <p className={styles.headline}>anticipate</p>
          <button className={styles.closeButton} onClick={closePanel}>
            <X />
          </button>
        </div>
        <text style={{ paddingTop: "16px" }}>Project Objective</text>
        <textarea
          className={styles.textarea}
          onChange={onChangeObjective}
          value={objective}
          placeholder="To create a new product that will help people in their daily lives."
        />
        <text style={{ paddingTop: "16px" }}>Project Constraints</text>
        <textarea
          className={styles.textarea}
          onChange={onChangeConstraint}
          value={constraint}
          placeholder="The project is in the design phase, and the team is composed of 5 people."
        />
      </div>
      <div className={styles.rest}>
        {/* <div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            className={styles.checkboxGpt}
            checked={gptFour}
            onChange={onGptCheckbox}
          />
          <label>GPT-4</label>
        </div> */}
        {/* <div className={styles.sliderGroup}>
          <div className={styles.smallGroup}>
            <span>Constraints - Opportunities:</span>
            <div className={styles.slider}>
              <CustomSlider
                value={opportunity}
                onChange={onOpportunityChange}
                min={0}
                max={100}
                step={10}
              />
              <input
                className={styles.input}
                type="number"
                value={opportunity}
                onChange={onOpportunityInputChange}
                min={0}
                max={100}
                step={10}
              ></input>
            </div>
          </div>
          <div className={styles.smallGroup}>
            <span>Desirability - Feasibility, Viability:</span>
            <div className={styles.slider}>
              <CustomSlider
                value={desirability}
                onChange={onDesirabilityChange}
                min={0}
                max={100}
                step={10}
              />
              <input
                className={styles.input}
                type="number"
                value={desirability}
                onChange={onDesirabilityInputChange}
                min={0}
                max={100}
                step={10}
              ></input>
            </div>
          </div>
          <div className={styles.smallGroup}>
            <span>Low - High Influence of SDGs:</span>
            <div className={styles.slider}>
              <CustomSlider
                value={sdg}
                onChange={onSdgChange}
                min={0}
                max={100}
                step={10}
              />
              <input
                className={styles.input}
                type="number"
                value={sdg}
                onChange={onSdgInputChange}
                min={0}
                max={100}
                step={10}
              ></input>
            </div>
          </div>
          <div className={styles.smallGroup}>
            <span>Temperature:</span>
            <div className={styles.slider}>
              <CustomSlider
                value={temperature}
                onChange={onTemperatureChange}
                min={0}
                max={100}
                step={10}
              />
              <input
                className={styles.input}
                type="number"
                value={temperature}
                onChange={onTemperatureInputChange}
                min={0}
                max={100}
                step={10}
              ></input>
            </div>
          </div>
          <div className={styles.smallGroup}>
            <span>Unknown:</span>
            <div className={styles.slider}>
              <CustomSlider
                value={unknown}
                onChange={onUnknownChange}
                min={0}
                max={100}
                step={10}
              />
              <input
                className={styles.input}
                type="number"
                value={unknown}
                onChange={onUnknownInputChange}
                min={0}
                max={100}
                step={10}
              ></input>
            </div>
          </div>
        </div> */}
        {/* <div className={styles.smallGroup}>
          <button className={styles.addAssumptionButton} onClick={onClick}>
            Add Assumption
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default memo(Panel);
